import React from 'react';

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import { m, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { useEffect } from "react";

const itemAnim = {
  visible: {opacity: 1, scale: 1, transition: { duration: 0.3, type: "spring", stiffness: 100 }},
  hidden: { opacity: 0, scale: 0 }

};

const containerAnim = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5, staggerChildren: 0.2 } },
  hidden: { opacity: 1, scale: 0.8 }
};

const LogoDump = ({className}) => {

  const data = useStaticQuery(graphql`
    query LogoDumpQuery {
      allClientesdataJson {
      edges {
        node {
          filename {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 90
                layout: CONSTRAINED
                width: 100
              )
            }
          }
          id
          description
        }
      }
    }
  }
  `);

  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);


return(

<m.section className={`bg-primary-default ${ className } `} id="logo-dump"
  ref={ref}
  initial="hidden"
  animate={control}
  variants={containerAnim}
>
  <div className="container mx-auto sm:flex "> 
    <div className=" w-full sm:flex-1 ">
    <h2 className=" pl-14 mx-auto text-3xl font-semibold text-white  mb-0 p-px pt-10 ">
        Empresas líderes en cada sector confían en nosotros:
      </h2>
   </div>
    <div className="w-full sm:flex-1 ">
     <div className="grid grid-cols-4 gap-4 pt-10 pb-10 pl-10 pr-10">
          {data.allClientesdataJson.edges.map( ({node}) => (
           <m.div key={node.id} className=" flex flex-grow flex-shrink justify-center  " variants={itemAnim}>
             <GatsbyImage
                image={getImage(node.filename)}
                className="rounded"
                alt={node.description}

              />

            </m.div>


          ))}
         </div>

    </div>
  </div>
</m.section>
);

}



export default LogoDump;
