import React from 'react';

import { CurrencyDollarIcon, LinkIcon, LightBulbIcon, BadgeCheckIcon, ScaleIcon } from '@heroicons/react/solid'

import { useStaticQuery, graphql } from "gatsby"

import { m, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { useEffect } from "react";
import IndexPage from '../pages';



const containerAnim = {
  visible: { opacity: 1, scale: 1, 
                transition: { 
                      duration: 0.5,
                      delayChildren: 1, 
                      
                      
                }
            },
  hidden: { opacity: 1, scale: 0.8 }
  
};








const ValueProposal = ({ subheading, label, intro }) => {

  const data = useStaticQuery(graphql`
    query ValueQuery {
      allFeaturesdataJson {
      edges {
        node {
          featureImage
          id
          title
          featureName
          featureLetter
          featureIcon
          content
        }
      }
    }
  }
  `);


  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  const icons = [
    CurrencyDollarIcon, 
    LinkIcon, 
    LightBulbIcon, 
    BadgeCheckIcon, 
    ScaleIcon
    // ...
  ] ;

return(

  
  <m.section
    id="value-proposal"

    className=""

    ref={ref}
    initial="hidden"
    animate={control}
    variants={containerAnim}

  >
    
    <div className="bg-gray-200  py-0">
      <div className="mx-auto w-full  pt-4 sm:pt-6 lg:pt-8">
        
              
              <h2 className="ml-4 md:ml-6 lg:ml-8 my-4 md:pb-4 text-xl font-normal text-center tracking-tight text-primary-lighter sm:text-md">
                {subheading}
              </h2>
              <h3 className="ml-4 md:ml-6 lg:ml-8 text-4xl font-semibold text-center  text-primary-lighter">{label}</h3>
      
      
              <p className="ml-4 md:ml-6 lg:ml-8 my-2 md:pt-4 lg:my-8 text-right text-2xl text-blue-900 w-[70vw]">
                {intro}                
              </p>

      </div>
              
      
      
      

      <div className=" relative isolate mx-auto w-full  

                        mt-2  pt-2 mb-0">

              
              <dl className=" text-zinc-700  
              mt-0 mx-auto pb-20 mb-20 mb-4 sm:mb-6 md:mb-8
              sm:max-w-[75vw] max-w-[90vw]">

              {data.allFeaturesdataJson.edges.map( ({node, index}) => ( 
               
              <div 
                key={node.id} 
                className="w-full mx-2 my-2 md:my-4 lg:my-6 
                relative rounded
                bg-white hover:bg-slate-100 bg-opacity-70 hover:bg-opacity-90 
                shadow-md hover:shadow-xl                
                
                p-4 md:p-6 lg:p-8
                min-h-fit

                hover:font-semibold                          
                hover:scale-105 hover:sm:scale-110 hover:md:scale-115 hover:lg:scale-120
                

                transition ease-in-out delay-50 duration-300
                ">
              
                    <dt className=" inline text-lime-500 text-2xl">                                   
                            

                      
                        <span className="inline mx-1">{node.featureLetter}</span>
                          &bull; 
                        <span className="inline ml-1">{node.title.substring(1)}</span>
                      
                    </dt>{' '}
                    <dd className="inline text-lg text-primary-default leading-loose ">
                      <p className="group ">
                        {node.content}                      
                      </p>
                      
                    </dd>

              
              </div>
              
            ))}
          </dl>
              <svg
            className="pointer-events-none 
            absolute right-0 top-1/3 md:top-1/2 lg:top-1/3
            -z-20 h-[42.375rem] 
            -translate-y-1/2 transform-gpu blur-3xl
            
            "
            viewBox="0 0 1155 678"
            fill="none"
          >
            <path
              fill="url(#c0458c57-1330-459f-9d5c-f0d75c210466)"
              fillOpacity=".35"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
           
          </svg>
      </div>
          
          
    </div>
      
    
      
  </m.section>


);

}

export default ValueProposal;
