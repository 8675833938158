import React, { useRef, useState } from "react";
import { Link } from 'gatsby';
import Button from './Button';


import { GatsbyImage, StaticImage,  getImage } from "gatsby-plugin-image"

// Import Swiper React components
import { Swiper, SwiperSlide, isActive } from "swiper/react";



// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";




// import required modules
import { EffectFade, Autoplay, Parallax, Pagination, Navigation } from "swiper";

const SwiperDivFade = ({className, message, cta, heading}) => {

  let swiperSlideClassGeneric = `relative p-0 bg-gray-500 sm:h-[65vh] md:h-[78vh] lg:h-[77vh] overlow-hidden parallax-bg`;
  let swiperSlideImgClassGeneric = `w-[100%] h-[85vh] sm:h-[80vh] md:h-[78vh] lg:h-[77vh] contrast-125 opacity-100`;
  let swiperSlideCaptionDivGeneric = `z-2 absolute bottom-12 -right-2  pl-2 pr-6 py-1 bg-blue-900 bg-opacity-80 rounded-sm`;
  let swiperSlideCaptionTextGeneric = `text-right text-xs font-semibold tracking-wider text-white  `;

  return (
    <>
      <Swiper
        effect={"fade"}
        fadeEffect= {{
          crossFade: true,
        }}
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
          "--swiper-navigation-size": "4vh",
          
        }}
        
        speed={640}
        
        loop={true}
        parallax={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2400,
        }}

        
        modules={[EffectFade, Autoplay, Parallax, Navigation, Pagination]}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        className={`  ${className} `}

     >

          <div id="hero-cta" className={`absolute z-10
            
            lg:left-10 lg:top-2 lg:max-w-[45vw] lg:pt-10 lg:pb-6 lg:mt-8 lg:px-8
            md:left-10 md:top-10 md:max-w-[45vw] md:pt-6 md:pb-4 md:px-6
            sm:left-8 sm:top-8 sm:max-w-[45vw] sm:pt-6 sm:pb-4 sm:px-6
            left-8 top-6 max-w-[65vw] max-h-[65vh] pt-6 pb-4 px-6
                        
            mx-auto
            bg-white bg-opacity-80 
            hover:bg-opacity-90 hover:-translate-y-1 hover:scale-105 hover:shadow-lg
            transition ease-in-out delay-50 duration-300  
            rounded-lg shadow-xl md:shadow-2xl
             `}>

              <h1 className="text-left 
              text-4xl  
              font-bold
              text-primary-lighter">
              {heading}
              </h1>

              <p className="mt-2 md:mt-4 
              sm:text-xl md:text-lg lg:text-xl 
              font-normal
              text-left lg:text-justify  tracking-wide text-primary-default">
                {message}
              </p>

              <Link to="productos/">
                <Button className="my-2 sm:my-8 md:my-6 
                  bg-blue-900 
                  text-white text-left 
                  sm:text-md md:text-md lg:text-lg 
                  font-semibold 
                  hover:bg-primary-lighter rounded-lg active:bg-primary-darker focus:outline-none"
                  size="md">{cta}
                </Button>
              </Link>


          </div>

        <SwiperSlide>
        {({ isActive }) => (
          <>

              <div className={swiperSlideClassGeneric + (isActive ? '' : 'opacity-0')} data-swiper-parallax='1' data-swiper-parallax-duration='12000' data-swiper-parallax-scale='1.05'>
                <StaticImage className={swiperSlideImgClassGeneric}  src="../images/hero-swiper-industria-alimenticia.jpg" alt="slide 1" quality={100}  layout="fullWidth" placeholder="blurred" />
                
              </div>
              <Link to="/soluciones-por-sector/industria-alimenticia/">
                <div className={swiperSlideCaptionDivGeneric}>
                  <h6 className={swiperSlideCaptionTextGeneric}>Productos especializados en industria de alimentos</h6>
                </div>
                </Link>
            
          </>
        )}
        </SwiperSlide>
        <SwiperSlide>
        {({ isActive }) => (
          <>
            
              <div className={swiperSlideClassGeneric + (isActive ? '' : 'opacity-0')} data-swiper-parallax='1' data-swiper-parallax-duration='12000' data-swiper-parallax-scale='1.05'>
                <StaticImage className={swiperSlideImgClassGeneric} src="../images/hero-swiper-absorbentes-biodegradables.jpg" alt="slide 1" quality={100} layout="fullWidth" placeholder="blurred" />
                
              </div>
              <Link to="/productos/quimicos/">
                <div className={swiperSlideCaptionDivGeneric}>
                  <h6 className={swiperSlideCaptionTextGeneric}>Absorbentes biodegradables</h6>
                </div>
                </Link>
            
          </>
        )}
        </SwiperSlide>
      
        <SwiperSlide>
        {({ isActive }) => (
          <>
            
              <div className={swiperSlideClassGeneric + (isActive ? '' : 'opacity-0')} data-swiper-parallax='1' data-swiper-parallax-duration='12000' data-swiper-parallax-scale='1.05'>
                <StaticImage className={swiperSlideImgClassGeneric} src="../images/hero-swiper-higiene-de-manos.jpg" alt="slide 1" quality={100} layout="fullWidth" placeholder="blurred" />
                
              </div>
              <Link to="/productos/quimicos/">
                <div className={swiperSlideCaptionDivGeneric}>
                  <h6 className={swiperSlideCaptionTextGeneric}>Productos profesionales para la higiene de manos</h6>
                </div>
                </Link>
            
          </>
        )}
        </SwiperSlide>
        <SwiperSlide>
        {({ isActive }) => (
          <>
            
              <div className={swiperSlideClassGeneric + (isActive ? '' : 'opacity-0')} data-swiper-parallax='1' data-swiper-parallax-duration='12000' data-swiper-parallax-scale='1.05'>
                <StaticImage className={swiperSlideImgClassGeneric} src="../images/hero-swiper-taski-7.jpg" alt="slide 1" quality={100} layout="fullWidth" placeholder="blurred" />
                
              </div>
              <Link to="/productos/quimicos/">
                <div className={swiperSlideCaptionDivGeneric}>
                  <h6 className={swiperSlideCaptionTextGeneric}>Máquinaria de limpieza con tecnología innovadora</h6>
                </div>
                </Link>
            
          </>
        )}
        </SwiperSlide>
        <SwiperSlide>
        {({ isActive }) => (
          <>
            
              <div className={swiperSlideClassGeneric + (isActive ? '' : 'opacity-0')} data-swiper-parallax='1' data-swiper-parallax-duration='12000' data-swiper-parallax-scale='1.05'>
                <StaticImage className={`${swiperSlideImgClassGeneric} responsive-image`} src="../images/hero-swiper-diversey-8.jpg" alt="slide 1" quality={100} layout="fullWidth" placeholder="blurred" />
                
              </div>
              <Link to="/productos/quimicos/">
                <div className={swiperSlideCaptionDivGeneric}>
                  <h6 className={swiperSlideCaptionTextGeneric}>Productos Diversey</h6>
                </div>
                </Link>
            
          </>
        )}
        </SwiperSlide>

        <SwiperSlide>
        {({ isActive }) => (
          <>
            
              <div className={swiperSlideClassGeneric + (isActive ? '' : 'opacity-0')} data-swiper-parallax='1' data-swiper-parallax-duration='12000' data-swiper-parallax-scale='1.05'>
                <StaticImage className={`${swiperSlideImgClassGeneric} responsive-image`} src="../images/hero-swiper-dispensador-toalla-z.jpg" alt="slide 1" quality={100} layout="fullWidth" placeholder="blurred" />
                
              </div>
              <Link to="/productos/quimicos/">
                <div className={swiperSlideCaptionDivGeneric}>
                  <h6 className={swiperSlideCaptionTextGeneric}>Línea de dispensadores</h6>
                </div>
                </Link>
            
          </>
        )}
        </SwiperSlide>

        <SwiperSlide>
        {({ isActive }) => (
          <>
            
              <div className={swiperSlideClassGeneric + (isActive ? '' : 'opacity-0')} data-swiper-parallax='1' data-swiper-parallax-duration='12000' data-swiper-parallax-scale='1.05'>
                <StaticImage className={swiperSlideImgClassGeneric} src="../images/hero-swiper-servicio-de-aromatizacion.jpg" alt="slide 1" quality={100} layout="fullWidth" placeholder="blurred" />
                
              </div>
              <Link to="/productos/quimicos/">
                <div className={swiperSlideCaptionDivGeneric}>
                  <h6 className={swiperSlideCaptionTextGeneric}>Servicio de aromatización y sanitización </h6>
                </div>
                </Link>
            
          </>
        )}
        </SwiperSlide>
       




      </Swiper>
    </>
  );
}

export default SwiperDivFade;
