import React from 'react';


import SwiperDiv from "./SwiperDivFade";





const HeroBannerSwiper = ({heading, message, cta}) => {





  return(

<section id="hero-banner-swiper" className={`w-full m-0 p-0 mx-auto object-contain `}>

     <SwiperDiv className="flex" heading={heading} message={message} cta={cta}  />
     
</section>



)};


export default HeroBannerSwiper
