import React from 'react';
import { Link } from 'gatsby';


import { StaticImage } from "gatsby-plugin-image"


import Layout from '../components/layout/Layout';


import Button from '../components/Button';


import LeadForm from "../components/LeadForm";
import NewsletterSub from "../components/NewsletterSub";
import Testimonials from "../components/Testimonials";

import LogoDump from "../components/LogoDump";


import HeroBannerSwiper from "../components/HeroBannerSwiper"
import ValueProposal from "../components/ValueProposal"


const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',

  },
  {
    label: 'Internacional',
    value: "/export-es/",

  },


];

const langOpts = [

  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: '',

  },


];

const IndexPage = ({  location }) => {




  return(

<Layout location={location} crumbLabel="Home Ecuador"
        siteVerOpts={siteVerOpts} langOpts={langOpts} alternates>


 

<HeroBannerSwiper
    heading="Soluciones de higiene profesional"
    message="Le brindamos la solución de higiene más adecuada para su empresa, 
    permitiéndole ahorrar gastos y mejorar la satisfacción de sus clientes desde el primer mes de uso, ¡GARANTIZADO!"
    cta="Descubrir la solución"
/>

<ValueProposal
  subheading="Mucho más que productos de limpieza..."
  label="NUESTRA PROMESA DE VALOR"
  intro="Le brindamos una solución de higiene profesional E.T.I.C.A.:"
/>





<section id="Somos fabricantes" className="mx-auto w-full">
  <div className="bg-blue-900 flex flex-col md:flex-row">
   <div className="flex-1">
    <StaticImage src="../images/prueba-banner-productos.jpg"
                      className="object-fit"
                      alt="Productos de higiene profesionales de alta calidad para exportación"
                      placeholder="blurred"
                      layout="constrained"/>
    </div>
    <div className="flex-1 px-4 py-4 sm:px-6 lg:px-8 lg:py-2">
    
      <h2 className="mt-4  text-4xl  font-semibold text-white text-left">
        Somos Fabricantes con más de 25 años de experiencia.
      </h2>
      <p className="mt-6 text-2xl text-left font-normal text-white ">
      Fabricamos el 80% de nuestro portafolio, lo que nos permite asegurarle abastecimiento. Nuestros productos son desarrollados con materia prima de la mejor calidad a un precio razonable. Estamos orgullosos de ser una empresa ecuatoriana.
      </p>
   
         <Link to="/productos/">
          <Button className="my-4 md:my-8 text-primary-default text-bold text-base  rounded-xl transition duration-200 ease-in-out bg-white shadow-md hover:bg-white hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none" size="s" type="submit">Ver portafolio</Button>
         </Link>
      
    </div>
  </div>
</section>

<section id="calidad de exportacion" className="bg-primary-lighter py-16">
  <div className="container mx-auto sm:flex ">
    <div className=" w-full lg:w-2/3 sm:flex-1 ">
      <h2 className=" mx-8 text-4xl  font-semibold text-left text-white my-4">
      Calidad de exportación
      </h2>
      <p className="text-white  text-2xl text-left font-normal  mx-8 pr-16 my-8 ">
      Cumplimos con certificaciones nacionales e internacionales, y, estamos comprometidos con el manejo forestal responsable: 
      </p>
   </div>
    <div className="w-full sm:flex-1 ">
     <div className="grid grid-cols-4 gap-4  pl-10 pr-10">

     <div className="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/logo-iso.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Cumplimos con la certificación ISO 9001 2015"
                    placeholder="blurred"
                    layout="constrained"/>
        </div>
       <div class="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/logo-issa.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Cumplimos con la certificación ISSA"
                    placeholder="blurred"
                    layout="constrained"/>
      </div> 

      <div className="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/sello-fsc.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Somos certificados FSC para nuestros absorbentes"
                    placeholder="blurred"
                    layout="constrained"/>
        </div>
        <div className="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/sello-bpm.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Cumplimos con el sello de buenas prácticas de manufactura"
                    placeholder="blurred"
                    layout="constrained"/>
        </div> 
        <div className="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/sello-mucho-mejor-ecuador.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Cumplimos con el sello de Mucho Mejor Ecuador"
                    placeholder="blurred"
                    layout="constrained"/>
        </div> 
        
         <div className="flex flex-grow flex-shrink justify-center">
          <StaticImage src="../images/logo-ceres.jpg"
                    className="flex justify-center my-4 rounded"
                    alt="Somos miembro de CERES"
                    placeholder="blurred"
                    layout="constrained"/>
        </div>
        
      </div>
    </div>
  </div>
</section>



<section id="Responsabilidad Social" className="">
<div className="relative bg-lime-500">
  <div className="h-56 bg-secondary-lighter sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../images/somos-sostenibles.jpg"
                      className=" flex justify-center"
                      alt="Unilimpio es una empresa ecuatoriana sostenible"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-6  sm:px-6 lg:px-8  pt-20">
    <div className="md:ml-auto md:w-1/2 md:pl-10 ">
      <h2 className="mt-4 text-4xl font-semibold text-white text-left">Somos Sostenibles</h2>
      <p className="mt-4 text-2xl text-left font-normal text-white">Somos una empresa responsable, transparente y solidaria. <br/>Nuestra empresa ha sido reconocida a nivel local por su compromiso en responsabilidad social empresarial. <br/> Formamos parte del Pacto Global de las Naciones Unidas. </p>
      <div className="mt-6">
      <div className="flex flex-wrap mx-auto text-left pb-6">
          <Link to="/responsabilidad-social" className="mt-2 text-primary-default inline-flex items-center text-xl text-bold">Conozca nuestra RSE
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
<LogoDump className=" "/>
<Testimonials className=" "/>



<LeadForm/>

<NewsletterSub/>

  </Layout>

)

}




export default IndexPage
