import React from 'react';



import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import { m, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { useEffect } from "react";

const itemAnim = {
  visible: {opacity: 1, scale: 1, transition: { duration: 0.3, type: "spring", stiffness: 100 }},
  hidden: { opacity: 0, scale: 0 }

};

const containerAnim = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5, staggerChildren: 0.2 } },
  hidden: { opacity: 1, scale: 0.5}
};


const Testimonials =({className}) => {

  const data = useStaticQuery(graphql`
    query TestimonialsQuery {
      allTestimonialsdataJson {
      edges {
        node {
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 90
                layout: CONSTRAINED
                width: 50
              )
            }
          }
          id
          name
          company
          content
        }
      }
    }
  }
  `);

  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);


  return(


    <m.section id="testimoniales" className={`w-full mx-auto bg-primary-lighter py-20 mb-0 mt-0${ className }`}
      ref={ref}
      initial="hidden"
      animate={control}
      variants={containerAnim}
    >
        <div>
            <h3 className="text-center  text-white text-4xl  font-semibold title-font pb-8">Lo que dicen nuestros clientes</h3>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 mx-6">

              {data.allTestimonialsdataJson.edges.map( ({node}) => (


                <m.li key={node.id}
                  className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"

                  variants={itemAnim}>
                    <div className="flex-1 flex flex-col p-8">
                    <GatsbyImage
                       className="mx-auto w-20 sm:w-40 my-4 "
                       image={getImage(node.image)}
                       alt={node.company}

                     />

                        <dl className="mt-1 flex-grow flex flex-col justify-between">

                          <figure className="quote">
                            <blockquote className="font-normal text-blue-900 text-xl italic">

                              {node.content}
                            </blockquote>
                            <figcaption className="font-semibold text-lg text-primary-lighter">
                              &mdash;{node.name}
                            </figcaption>
                          </figure>

                        </dl>
                    </div>

                </m.li>

            ))}

        </div>
</m.section>

);
}

export default Testimonials;
